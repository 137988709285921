<template>
  <div class="article">
    <div class="contianner">
      <main class="main-left" role="main">
        <article class="post type-post">
          <div class="entry-header">
            <h1 class="entry-title">{{ articleTitle }}</h1>
            <div class="time">
              <span>{{ newDate }}</span>
            </div>
          </div>
          <div class="entry-content" v-html="articleHtml"></div>
        </article>
        <div class="article_tags">
          <div class="tags" @mouseout="hoverNone('tags')">
            <div class="label">本文标签：</div>
            <ul class="tags_lis">
              <li
                class="tags_item"
                v-for="(item, index) in tags_name"
                :key="index"
              >
                <a href="javascript:;" @click="goKnowledeg(item.id)">{{
                  item.name
                }}</a>
              </li>
            </ul>
          </div>
          <div class="share">
            <ul>
              <li class="share_lis">
                <a :href="share_list[0].type" target="_blank"
                  ><img :src="share_list[0].img" alt=""
                /></a>
              </li>
              <li class="share_lis">
                <a :href="share_list[1].type" target="_blank"
                  ><img :src="share_list[1].img" alt=""
                /></a>
              </li>
              <li
                class="share_lis wx"
                @mouseover="hover(null, 'wx')"
                @mouseout="hoverNone('wx')"
              >
                <a href="javascript:;"
                  ><img :src="share_list[2].img" alt=""
                /></a>
                <div class="box" :class="{ db: wxEwm == false }">
                  <span class="title">微信扫一扫:分享</span>
                  <div id="qrcode"></div>
                </div>
              </li>

              <li class="share_lis">
                <a :href="share_list[3].type" target="_blank"
                  ><img :src="share_list[3].img" alt=""
                /></a>
              </li>
              <li
                class="share_lis qw"
                @mouseover="hover(null, 'qw')"
                @mouseout="hoverNone('qw')"
              >
                <a href="javascript:;"
                  ><img :src="share_list[4].img" alt=""
                /></a>
                <div class="box" :class="{ db: qwEwm == false }">
                  <span class="title">企业微信扫一扫:分享</span>
                  <div id="qrcodeCopy"></div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </main>
      <aside class="sidebar widget-area" role="complementary">
        <section
          class="widget widget_categories"
          @mouseout="hoverNone('recom')"
        >
          <h2 class="widget-title">新闻推荐</h2>
          <ul>
            <li class="new_list" v-for="(item, index) in newsList" :key="index">
              <a :href="`/article/${item.id}`" target="_blank">
                <div class="new_img">
                  <img :src="item.thumb_image" alt="" />
                </div>
                <div
                  :class="[
                    'new_des',
                    { hoverColor: recomIdx === index ? 'hoverColor' : '' },
                  ]"
                  @mouseover="hover(index, 'recom')"
                >
                  <span>
                    {{ item.title.rendered }}
                  </span>
                </div>
              </a>
            </li>
          </ul>
        </section>

        <section class="moc_knowledge" @mouseout="hoverNone('knowledge')">
          <div class="knowledge_title">
            <a href="/knowledge-base">
              <span class="moc_title">魔筷知识库</span>
              <span class="img"></span>
            </a>
          </div>

          <ul class="knowledge_main">
            <li
              class="knowledge_lis"
              v-for="(item, index) in knowledgeList"
              :key="index"
              @mouseover="hover(index, 'knowledge')"
            >
              <a :href="`/article/${item.id}`" target="_blank">
                <div class="left">{{ index + 1 }}</div>
                <span
                  :class="[
                    'right',
                    { hoverColor: knowIdx === index ? 'hoverColor' : '' },
                  ]"
                  >{{ item.title.rendered }}</span
                >
              </a>
            </li>
          </ul>
        </section>

        <section class="Follow_moc">
          <div class="follow_title">关注魔筷科技</div>
          <div class="erm">
            <img
              src="https://mktv-in-cdn.mockuai.com/16345579148937829.png"
              alt=""
            />
            <div class="text">了解更多一手资讯</div>
          </div>
        </section>
      </aside>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import {
  right,
  newsWb,
  newsQw,
  newsWx,
  newsQq,
  newsQlife,
} from "../static/base64";
export default {
  // needPrefetch: true,
  needSerialize: true,
  data() {
    return {
      categories: [],
      articleHtml: "",
      articleTitle: "",
      description: "",
      newDate: "",
      newsList: [],
      right,
      knowledgeList: [],
      tags: [],
      tags_name: [],
      recomIdx: "",
      knowIdx: "",
      tagIdx: "",
      newPic: "",
      wxEwm: false,
      qwEwm: false,
      share_list: [
        {
          img: newsWb,
          type: ``,
        },
        {
          img: newsQq,
          type: ``,
        },
        { img: newsWx, type: "javascript:;" },

        {
          img: newsQlife,
          type: ``,
        },
        {
          img: newsQw,
          type: ``,
          name: "qw",
        },
      ],
    };
  },

  head() {
    return {
      title: this.articleTitle,
      meta: [{ name: "description", content: this.description }],
    };
  },

  async created() {
    await this.getArticleDetail();
    await this.getNewsList();
    await this.getKnowledge();
    await this.getTags();
  },

  methods: {
    async getArticleDetail() {
      try {
        const { id } = this.$route.params;
        const res = await axios.get(
          `https://wp.mockuai.com/wp-json/wp/v2/posts/${id}`
        );
        // this.$nextTick(() => {
        //   console.log(res, "adfas");
        // });
        if (res.data) {
          this.articleHtml = res.data.content.rendered;
          this.articleTitle = res.data.title.rendered;
          this.description = res.data.excerpt.rendered
            .replace(/<(p|div)[^>]*>(<br\/?>|&nbsp;)<\/\1>/gi, "\n")
            .replace(/<br\/?>/gi, "\n")
            .replace(/<[^>/]+>/g, "")
            .replace(/(\n)?<\/([^>]+)>/g, "")
            .replace(/\u00a0/g, " ")
            .replace(/&nbsp;/g, " ")
            .replace(/<\/?(img)[^>]*>/gi, "")
            .replace(/&amp;/g, "&")
            .replace(/&lt;/g, "<")
            .replace(/&gt;/g, ">")
            .replace(/&#39;/g, "'")
            .replace(/&quot;/g, '"')
            .replace(/<\/?.+?>/g, "")
            .replace(/&hellip;/g, "...");

          this.newDate = res.data.date + "";

          this.tags = res.data.tags;
          // this.$nextTick(() => {
          // console.log(res.data, '新闻详情')
          // })
          this.newPic = res.data.thumb_image;
        }
      } catch (error) {
        console.error("新闻详情获取失败");
      }
    },
    async getNewsList() {
      try {
        const res = await axios.get(
          "https://wp.mockuai.com/wp-json/wp/v2/posts?per_page=5&orderby=date"
          // 'https://www.mockuai.com/wp-json/wp/v2/posts?per_page=6&orderby=date&categories=9'
        );
        this.newsList = res.data;
      } catch (error) {
        console.error(error || "获取新闻数据失败");
      }
    },
    async getKnowledge() {
      try {
        const res = await axios.get(
          "https://www.mockuai.com/wp-json/wp/v2/posts?per_page=6&orderby=date&categories=9"
        );
        this.knowledgeList = res.data;
      } catch (error) {
        console.error(error || "获取新闻数据失败");
      }
    },

    async getTags() {
      try {
        const res = await axios.get(
          `https://wp.mockuai.com/wp-json/wp/v2/tags?include=${this.tags.join()}`
        );
        this.tags_name = res.data;
      } catch (error) {
        console.error(error || "获取标签列表失败");
      }
    },
    hover(index, source) {
      if (source == "recom") {
        this.recomIdx = index;
      }
      if (source == "knowledge") {
        this.knowIdx = index;
      }
      if (source == "tags") {
        this.tagIdx = index;
      }
      if (source === "wx") {
        this.wxEwm = true;
        console.log(this.wxEwm, source);
      }
      if (source === "qw") {
        this.qwEwm = true;
      }
    },
    hoverNone(source) {
      this.recomIdx = source == "recom" ? "" : this.recomIdx;
      this.knowIdx = source == "knowledge" ? "" : this.knowIdx;
      this.tagIdx = source == "tags" ? "" : this.tagIdx;
      if (source == "wx") {
        this.wxEwm = false;
      }
      if (source == "qw") {
        this.qwEwm = false;
      }
    },
    setShare() {
      this.share_list[0].type = `https://service.weibo.com/share/share.php?url=http://www.mockuai.com${this.$route.path}/&title=${this.articleTitle}|官方资讯-魔筷科技&pic=${this.newPic}`;
      this.share_list[1].type = `http://connect.qq.com/widget/shareqq/index.html?url=http://www.mockuai.com${this.$route.path}/&title=${this.articleTitle}|官方资讯-魔筷科技&pics=${this.newPic}`;
      this.share_list[3].type = `http://sns.qzone.qq.com/cgi-bin/qzshare/cgi_qzshare_onekey?url=http://www.mockuai.com${this.$route.path}/&title=${this.articleTitle}|官方资讯-魔筷科技&&desc=${this.description}&summary=${this.description}&site=${this.articleTitle}'&pics=${this.newPic}`;
    },
    qrcode() {
      new this.$qrcode("qrcode", {
        text: `https://www.mockuai.com${this.$route.fullpath}`, // 二维码地址
      });
      new this.$qrcode("qrcodeCopy", {
        text: `https://www.mockuai.com${this.$route.fullpath}`, // 二维码地址
      });
    },

    goKnowledeg(id) {
      // console.log(id)
      this.$router.push({
        name: "knowledge-base",
        params: {
          id: id,
        },
      });
    },
  },
  mounted() {
    if (this.newDate) {
      let date = this.newDate.split("");
      let idx = date.indexOf("T");
      date.splice(idx);
      this.newDate = date.join("");
      this.newDate = this.newDate.replace(/-/g, ".");
    }
    this.setShare();
    this.qrcode();
  },
};
</script>

<style lang="less" scoped>
@import "../style/common.less";

h1,
h2 {
  margin: 0;
  padding: 0;
}
.article {
  width: 1440px;
  margin: 0 auto;
  padding-top: 90px;
  .pxToVw(margin-top, 50);
  // .pxToVw(margin-left, 240);
  // .pxToVw(margin-right, 240);
  // margin-right: 240px;
  // margin-left: 240px;

  overflow: hidden;
  display: flex;
  flex-direction: column;
  .contianner {
    display: flex;
  }
  .main-left {
    .pxToVw(margin-left, 50);
    padding-bottom: 50px;
    .entry-header {
      .entry-title {
        font-weight: bold;
      }
      .time {
        .pxToVw(margin-top, 8);
        .pxToVw(font-size, 18);
        .pxToVw(line-height, 25);
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
      }
    }
    .entry-content {
      /deep/ .wp-block-image {
        max-width: 100%;
        margin: 0;
        margin-bottom: 1em;
        img {
          max-width: 100%;
        }
        .aligncenter {
          margin-left: auto;
          margin-right: auto;
          img {
            max-width: 100%;
          }
        }
      }
    }
  }
  .article_tags {
    .pxToVw(margin-top, 81);
    display: flex;
    justify-content: space-between;

    .tags {
      display: flex;
      align-items: center;
    }
    .tags_lis {
      display: flex;
      cursor: pointer;
      .tags_item {
        .pxToVw(margin-right, 20);
        .pxToVw(height, 40);
        .pxToVw(padding-left,10);
        .pxToVw(padding-right,10);
        .pxToVw(line-height, 40);
        .pxToVw(border-radius, 4);
        background: #f7f7f7;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        a {
          text-decoration: none;
          .pxToVw(font-size, 14);
          .pxToVw(line-height, 20);
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
        }
        .orgColor {
          color: #fff;
        }
      }
      .tags_item:hover {
        background: #ff6600;
      }
      .tags_item:last-of-type {
        margin-right: 0;
      }
      .tags_item:hover a {
        color: #fff;
      }
    }
    .label {
      .pxToVw(font-size, 16);
      .pxToVw(line-height, 25);
      cursor: pointer;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
    }
  }
  .share {
    ul {
      display: flex;
      .share_lis {
        .pxToVw(padding-right, 20) !important;
        cursor: pointer;
        img {
          display: block;
          .pxToVw(width, 44);
          .pxToVw(height, 44);
        }
      }
      .wx,
      .qw {
        position: relative;
        .box {
          display: flex;
          flex-direction: column;
          align-items: center;
          .pxToVw(width, 250);
          .pxToVw(height, 250);
          background-color: #f7f7f7;
          position: absolute;
          left: 0;
          top: 0;
          transform: translate(-40%, -110%);
          .title {
            .pxToVw(font-size, 16);
            .pxToVw(padding-top, 20);
            .pxToVw(padding-bottom, 20);
          }
        }
        #qrcode,
        #qrcodeCopy {
          /deep/ img {
            display: block;
            .pxToVw(width, 160);
            .pxToVw(height, 160);
          }
        }
        .db {
          display: none !important;
        }
      }
    }
  }
  .entry-title {
    font-size: 30px;
  }
  /deep/ p {
    font-size: 16px;
    .pxToVw(line-height, 40);
    line-height: 2;
    font-family: PingFangSC-Regular, PingFang SC;
  }
  /deep/ video {
    width: 100% !important;
  }
  /deep/ img {
    display: block;
    margin: 0 auto;
    width: 80%;
    text-align: center;
  }
  /deep/ figcaption {
    text-align: center;
  }
  /deep/ h1 {
    font-size: 2.44em;
  }
  /deep/ h2 {
    font-size: 1.95em;
  }
  /deep/ h3 {
    font-size: 1.56em;
  }
  /deep/ h4 {
    font-size: 1.25em;
  }
  /deep/ h5 {
    font-size: 1em;
  }
  /deep/ h6 {
    font-size: 0.8em;
  }
  .sidebar {
    .pxToVw(margin-left, 80);
    .pxToVw(width, 450);
    align-self: right;
    .widget_categories {
      overflow: hidden;
      .pxToVw(width, 400);
      .pxToVw(padding-top, 35);
      .pxToVw(padding-left, 30);
      .pxToVw(padding-bottom, 60);
      .pxToVw(padding-right, 30);
      box-sizing: border-box;
      .pxToVw(border-radius,16);

      background: #ffffff;
      box-shadow: 0px 2px 24px 1px rgba(0, 0, 0, 0.1);

      .new_list {
        a {
          display: flex;
          text-decoration: none;
          align-items: center;
        }
        .pxToVw(margin-top, 20);
        .new_img {
          .pxToVw(width, 108);
          .pxToVw(height, 61);
          .pxToVw(border-radius, 4);
          .pxToVw(margin-right, 10);
          img {
            .pxToVw(width, 108);
            .pxToVw(height, 61);
            display: block;
            object-fit: cover;
            border-radius: 4px;
          }
        }
        .new_des {
          .pxToVw(font-size, 18);
          .pxToVw(width, 280);
          font-family: PingFangSC-Semibold, PingFang SC;
          color: #333333;
          span {
            overflow: hidden;
            display: -webkit-box;
            font-weight: 600;
            overflow: hidden;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            line-height: 1.5;
          }
        }
      }

      .widget-title {
        .pxToVw(padding-bottom, 10);
      }
    }
    .moc_knowledge {
      cursor: pointer;
      background: url(https://mktv-in-cdn.mockuai.com/16347881491189021.png)
        no-repeat;
      background-size: contain;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 24px 1px;
      box-sizing: border-box;
      .pxToVw(width, 400);
      .pxToVw(height, 408);
      .pxToVw(border-radius, 11);
      .pxToVw(margin-top, 40);
      .pxToVw(padding-top, 35);
      .pxToVw(padding-left, 30);
      .pxToVw(padding-right, 30);
      .pxToVw(padding-bottom, 60);
      .moc_title {
        .pxToVw(font-size, 24);
      }
      .knowledge_title {
        a {
          .pxToVw(font-size, 24);
          .pxToVw(line-heiht, 33);
          text-decoration: none;
          color: #ff6600;
          font-family: DIN-MediumItalic, DIN;
          display: flex;
          align-items: center;
        }
        color: #ff6600;

        .img {
          .pxToVw(width, 7);
          .pxToVw(height, 12);
          background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAYCAYAAADKx8xXAAAAAXNSR0IArs4c6QAAAPRJREFUOE+d1D1qAkEUwPH/CySpUnmRFF4gaCWINmohpNwtQlpvEtA9gJgEYuMZPIK9lSAEUqUIyAsziSbD7uzMOPX8eB8z74lOuOGDgxR8knBEM7YIV0BLZmxirYEvwABhD9zFYtFHrvniDaWTgsWkdg628Bx8gqnYgSm4BGNxJYzBXhjCtbASK7dSsAtCi3NGKAv7bhd0ZcoqCDWnh/IKXCI802IsQw71NXqQCezvag3yQl96/0eu/HMCkY7Y/asZfbDz6TSiarj/piMBnWrURGSh5jRR1jHpOc35jbYE5rS5N48bs7B+VscDDXniPQYc73wDVriOGWZFkkcAAAAASUVORK5CYII=);
          background-repeat: no-repeat;
          background-size: contain;
          .pxToVw(margin-left,10);
        }
      }
      .knowledge_title :hover .moc_title {
        font-weight: 600;
        color: #2e6fed;
      }
      .knowledge_title :hover .img {
        background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAYCAYAAADKx8xXAAAAAXNSR0IArs4c6QAAARFJREFUOE9jtC77z/uDi+Hv2QbGbwwkAEa9/HcPGRj+szGzsDmf7+W9RqxeRr38t6sYGBhCGRkZXjExszkSq5nRc9J/9qd3369jYPjvRYpmRpDTyNEM1kiOZrhGUjWjaCRFM4ZGYjVj1UiMZpwaCWnGqxGbZhYGDoOzE7ifE9QI0qyf/zb8PwPDChCbkZnR92Kf0BaCGg0K3/v///d/9X+G/6yMjIwr1awEo1eHMf7FqxGXJrDNuHIDPk04NRLShFUjMZowNBoUvA/4////KvSAwOYduB9J0QS3kVRNYI2GhR9M/v37d4wY5yE7mRFk27///9YzMDAuU7cWjANFLjEFFtiPZpWfhE+1870lRgNMDQBj/uIZ6VqAPwAAAABJRU5ErkJggg==);
        background-repeat: no-repeat;
        background-size: contain;
      }
      .knowledge_lis:nth-of-type(1) {
        .left {
          background: red;
        }
      }
      ul :nth-child(2) {
        .left {
          background: #ee6a0c;
        }
      }
      ul :nth-child(3) {
        .left {
          background: #f9ab0d;
        }
      }
      .knowledge_main {
        a {
          text-decoration: none;
          display: flex;
          align-items: center;
        }
        .knowledge_lis {
          .left {
            .pxToVw(width, 25);
            .pxToVw(border-radius, 4);
            .pxToVw(font-size, 18);
            .pxToVw(margin-right, 10);
            background: #dddddd;
            text-align: center;
            font-family: DIN-MediumItalic, DIN;
            font-weight: normal;
            color: #ffffff;
          }
          .right {
            flex: 1;
            .pxToVw(font-size, 16);
            .pxToVw(line-height, 22);
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
          }
        }
      }
      .knowledge_main :nth-child(2) {
        .left {
          background: #ee6a0c;
        }
      }
      .knowledge_main :nth-child(3) {
        .left {
          background: #f9ab0d;
        }
      }
      .knowledge_lis:nth-of-type(1) {
        .pxToVw(margin-top, 39);
        .left {
          background: red;
        }
      }
      .knowledge_lis {
        .pxToVw(margin-bottom, 25);
      }
    }

    .Follow_moc {
      .pxToVw(width, 400);
      .pxToVw(height, 448);
      .pxToVw(border-radius, 16);
      .pxToVw(margin-top, 44);
      .pxToVw(padding-top, 35);
      .pxToVw(padding-left, 30);
      .pxToVw(padding-right, 30);
      .pxToVw(margin-bottom, 50);
      box-sizing: border-box;
      background: #ffffff;
      box-shadow: 0px 2px 24px 0px rgba(0, 0, 0, 0.1);
      .follow_title {
        .pxToVw(font-size, 24);
        .pxToVw(line-height, 33);
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #333333;
      }
      .erm {
        text-align: center;
        .pxToVw(margin-top, 35);
        img {
          .pxToVw(width, 240);
          .pxToVw(height, 240);
        }
        .text {
          .pxToVw(font-size, 18);
          .pxToVw(margin-top, 20);
          .pxToVw(line-height, 25);
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
        }
      }
    }
    .hoverColor {
      color: #2e6fed !important;
    }
  }
  @media screen and (max-width: 960px) {
    .main-left {
      width: 560px;
    }
    .contianner {
      .pxToVw(margin-right, 40);
      .pxToVw(margin-left,40);
    }
  }
  @media screen and (min-width: 960px) and (max-width: 1230px) {
    .main-left {
      width: 560px;
    }
    .contianner {
      .pxToVw(margin-right, 140);
      .pxToVw(margin-left,140);
    }
  }
  @media screen and (min-width: 1230px) and (max-width: 1440px) {
    .main-left {
      width: 720px;
    }
    .contianner {
      .pxToVw(margin-right, 140);
      .pxToVw(margin-left,140);
    }
  }
  @media screen and (min-width: 1440px) {
    .main-left {
      width: 800px;
      .pxToVw(margin-right, 0);
      .pxToVw(margin-left,0);
    }

    .entry-title {
      font-size: 36px;
    }
    .time {
      font-size: 18px;
    }
    .article_tags {
      .tags_lis {
        .tags_item {
          margin-right: 20px;
          height: 40px;
          width: 80px;
          padding: 0 10px;
          background: #f7f7f7;
          line-height: 40px;
          text-align: center;
          a {
            font-size: 14;
          }
        }
      }
      .label {
        font-size: 16px;
      }
    }
    .share {
      ul {
        .share_lis {
          padding-right: 20px;
          img {
            width: 44px;
            height: 44px;
          }
        }
        .wx,
        .qw {
          .box {
            width: 250px;
            height: 250px;
            .title {
              font-size: 16px;
              padding-top: 20px;
              padding-bottom: 20px;
            }
          }
          #qrcode,
          #qrcodeCopy {
            /deep/ img {
              display: block;
              width: 160px;
              height: 160px;
            }
          }
          .db {
            display: none !important;
          }
        }
      }
    }

    .sidebar {
      margin-left: 80px;
      width: 450px;
      .widget_categories {
        width: 400px;
        padding-top: 35px;
        padding-left: 30px;
        padding-bottom: 60px;
        padding-right: 30px;
        border-radius: 16px;
        background: #ffffff;
        box-shadow: 0px 2px 24px 1px rgba(0, 0, 0, 0.1);

        .new_list {
          margin-top: 20px;
          a {
            display: flex;
          }
          .new_img {
            width: 108px;
            height: 61px;
            border-radius: 4px;
            margin-right: 10px;
            img {
              width: 100%;
              height: 100%;
              display: block;
              object-fit: cover;
            }
          }
          .new_des {
            font-size: 18px;
            flex: 1;
          }
        }
        .widget-title {
          padding-bottom: 10px;
        }
      }
      .moc_knowledge {
        width: 400px;
        height: 408px;
        font-size: 18px;
        margin-top: 40px;
        padding: 35px 30px 60px;
        .moc_title {
          font-size: 24px;
        }
        .knowledge_main {
          .knowledge_lis {
            margin-bottom: 20px;
            a {
              .right {
                font-size: 18px;
              }
              .left {
                width: 25px;
                height: 24px;
                line-height: 24px;
                border-radius: 4px;
                font-size: 18px;
                margin-right: 10px;
              }
            }
          }
        }

        .knowledge_lis:nth-of-type(1) {
          margin-top: 30px;
          .left {
            background: red;
          }
        }
      }
      .Follow_moc {
        width: 400px;
        height: 448px;
        border-radius: 16px;
        margin-top: 44px;
        padding: 35px 30px 0;
        .follow_title {
          font-size: 24px;
        }
        .erm {
          margin-top: 35px;
          img {
            width: 240px;
            height: 240px;
          }
          span {
            font-size: 18px;
            margin-top: 20px;
          }
        }
      }
    }
  }
}
@import "../style/style.css";
</style>
